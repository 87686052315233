body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "UKIJ Tuz Kitab";
  src: local("UKIJ Tuz Kitab"), url(./assets/fonts/ukijtuzkb.ttf) format("truetype");
}

.ug {
  font-family: 'UKIJ Tuz Kitab';

}

.ug a,
.ug span,
.ug p,
.ug div,
.ug th,
.ug td,
.ug li,
.ug button {
  font-family: 'UKIJ Tuz Kitab';
}