@import 'variables';

.distribute {
	.order-list {
		.css-bhp9pd-MuiPaper-root-MuiCard-root {
			min-width: 620px;
		}
	}
	.deliveryman-list {
		margin: 24px 0;
		.table {
			.body {
				.row {
					height: 40px;
				}
			}
		}
		.list {
			overflow: scroll;
		}
	}
}
