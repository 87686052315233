@import 'variables';

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

body {
	max-height: 500px;
}

.RaSidebar-fixed {
	box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
	background: #fff;
}

.RaMenuItemLink-active {
	background-color: #e6f7ff;
	color: #1890ff;
}

.amap-marker-label {
	width: 60px;
	font-size: 16px;
	line-height: 18px;
	text-align: center;
	font-family: STLiti;
	color: green;
	border-radius: 15px;
	background-color: rgba(255, 255, 255, 0.4);
	border: solid 2px rgba(100, 255, 200, 0.8);
}

.deliveryman-list {
	font-size: 12px;
}

.deliveryman-list .table .body .selected {
	border: rgba(100, 255, 200, 0.8);
	background-color: #daf5ff;
}

.order-list .table .body .selected {
	border: rgba(100, 255, 200, 0.8);
	background-color: #daf5ff;
}

.order-list {
	height: 920px;
	overflow: scroll;
}

.deliveryman-list {
	height: 990px;
	overflow: scroll;
}

.MuiPopover-root {
	div,
	th,
	td,
	input,
	button,
	a,
	p,
	li {
		font-family: 'UKIJ Tuz Kitab';
	}
}
.css-asicq .MuiPaper-root{
  width: 160px;
}